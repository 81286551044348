








import TransactionsTableCard from '@/views/StatisticsView/TransactionsTableCard.vue';

export default {
  name: 'UserManagementTransactionsTab',
  components: { TransactionsTableCard },
  props: {
    operatorUserId: {
      type: String,
      default: (): string => null
    },
    operatorUserCreatedAt: {
      type: String,
      default: (): string => ''
    }
  }
};
